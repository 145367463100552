import { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

const Pressrelease: FunctionComponent = () => {
  const { t } = useTranslation();

  const pressReleases = [
      {
        date: "2024.11.27",
        content: "起業プラザひょうごにて開催予定のオープンイノベーション交流会にOliveが登壇いたします。",
        href: "https://kobe-bizmatch.jp/startup/seminar/139"
      },
      {
        date: "2024.09.26",
        content: "東京ビッグサイトにて行われるツーリズムEXPOジャパンに於いて、名鉄観光サービス株式会社と共に『観光者の感情を測ることによる感情マッピングの活用と地域創生に向けた取組』及び『スポーツ観戦における観戦者と店舗での感情計測を活用した新たな取組』を行う展示を行なっております。",
        href: "https://www.t-expo.jp/"
      },
      {
        date: "2024.09.26",
        content: "東京ビッグサイトにて行われるツーリズムEXPOジャパンに於いて、東武トップツアーズ株式会社が展開する『あらゆる商品をライブでマーケティングできる未来型空間』AZLMでの『来店顧客の感情計測によるマーケティングデータ取得』及び『観光者の感情を測ることによる感情マッピングの活用』を行う展示を行なっております。",
        href: "https://www.t-expo.jp/"
      },
      {
        date: "2024.09.20",
        content: "サーラエナジー株式会社および豊橋総合動植物公園と共に感情の可視化技術を使った取組を開始致します。",
        href: "https://www.salaenergy.co.jp/media/press20240920.pdf"
      },
      {
        date: "2024.09.20",
        content: "株式会社Yuinchu及びBREATHER株式会社と共に『深い呼吸での休み方改革/はたらく環境改善』を目指す法人向けサービスを始めました。",
        href: "https://prtimes.jp/main/html/rd/p/000000021.000073545.html"
      },
      {
        date: "2024.09.02",
        content: "東京と世界のイノベーションエコシステムを繋ぎ新たな時代を切り拓くスタートアップをアクセラレートするプラットフォームであるX-HUB TOKYOにて採択されました。",
        href: "https://www.x-hub-tokyo.metro.tokyo.lg.jp/startups_outbound"
      },
      {
        date: "2024.08.22",
        content: "高松市にて行われた『玉藻あかり物語』にて来場者の感情を計測した「感情マッピング」が行われました。",
        href: "https://www.city.takamatsu.kagawa.jp/smph/kurashi/shinotorikumi/citypromotion/tamamo.html"
      },
      {
        date: "2024.07.29",
        content: "神戸商工会議所が主催するオープンイノベーション交流会に弊社が登壇いたします。",
        href: "/20240729_koube.pdf"
      },
      {
        date: "2024.07.26",
        content: "Plug and Play Japan株式会社と愛知県に因り、Singapore Week of Innovation & Technology 2024「SWITCH 2024」へ出展するスタートアップとして選定されました。",
        href: "https://prtimes.jp/main/html/rd/p/000000201.000028153.html"
      },
      {
        date: "2024.06.19",
        content: "タイ・バンコクで開催される、ASEAN各国と日本のスタートアップが参加するビジネスマッチングイベント「Japan-ASEAN Startup Business Matching Fair 2024」に出展し、弊社の行なっている感情データの活用について講演致します。当イベントは三菱UFJフィナンシャル・グループのアユタヤ銀行が主催し、ASEAN各国や日本から審査通過したスタートアップと多数の事業会社及びベンチャーキャピタルが参加します。",
      },
      {
        date: "2024.06.10",
        content: "6月12日より幕張メッセにて行われるデジタルサイネージ全般の展示会『デジタルサイネージ ジャパン（DSJ）』にて、株式会社電算システムのデジタルサイネージソリューションの1つのとして弊社感情推定プラットフォームを展示頂きます。",
        href: "/dsj2024_dsk_olive.pdf"
      },
      {
        date: "2024.05.16",
        content: "弊社は三谷産業株式会社と共にいしかわ情報システムフェア「e-messe kanazawa」に出展致します。",
        href: "https://www.e-messe.jp/"
      },
      {
        date: "2024.05.07",
        content: "弊社の取組が中日新聞に取り上げられました。",
        href: "https://www.chunichi.co.jp/article/895158"
      },
      {
        date: "2024.04.11",
        content: "介護現場における被介護者の状態の見える化実証事業を開始致しました。",
        href: "https://www.city.obu.aichi.jp/shisei/koho/pressrelease/1003447/1030997/1031054.html"
      },
      {
        date: "2024.02.13",
        content: "福岡未踏的人材発掘・育成コンソーシアム 3期 Solve スポンサーとして参画致しました。",
        href: "https://mitou-fukuoka.org/works/face-down-detection-system/"
      },
      {
        date: "2024.01.24",
        content: "Olive株式会社は、革新的な女性向け製品サービスを紹介する「ジェンダード・イノベーションEXPO 2024年」展示会に出展いたします。",
        href: "https://womanslabo.com/genderedinnovation2024"
      },
      {
        date: "2024.01.22",
        content: "AICHI INNOVATION DAYS 2024（3月11日(月)から2day）にて弊社が登壇及び出展致します。",
        href: "https://stationai.co.jp/aichi-innovationdays2024"
      },
      {
        date: "2023.12.21",
        content: "三谷産業株式会社（本社：石川県金沢市／代表取締役社長：三谷 忠照、以下 三谷産業）が行った『MITANI Business Contest 2023』にて102 件の応募の中から選ばれたファイナリスト8社の中で弊社が優秀賞に選ばれました。",
        href: "https://www.mitani.co.jp/news/20231221"
      },
      {
        date: "2023.12.18",
        content: "中国清華大学 幸福科技実験室（Happiness Technology Lab）と研究・技術開発の協業を開始。",
        href: "http://psytest.tusstar.com/"
      },
      {
        date: "2023.12.18",
        content: "BIPROGYによる事業共創プロジェクト『DiCE』より生まれた、損害保険大手のあいおいニッセイ同和損保との取組となる大企業の人事部向けGLTD（団体長期障害所得補償保険）付帯サービス展開が紹介されました。",
        href: "https://tomoruba.eiicon.net/articles/4426"
      },
      {
        date: "2023.11.28",
        content: "三谷産業株式会社（本社：石川県金沢市／代表取締役社長：三谷 忠照、以下 三谷産業）が行う『MITANI Business Contest 2023』にて102 件の応募の中から弊社が8社の内の1社に選ばれました。",
        href: "https://www.mitani.co.jp/mbc/"
      },
      {
        date: "2023.10.26",
        content: "弊社の店舗、学校及びエンタメでの取組がNHKにて紹介されました。",
        href: "https://www.nhk.jp/p/jikentokoro/ts/G69KQR33PG/"
      },
      {
        date: "2023.10.20",
        content: "あいおいニッセイ同和損害保険株式会社と共に「従業員のメンタルリスク検知とケア」事業を進めることが発表されました。",
        href: "https://www.biprogy.com/pdf/news/nr_231020.pdf"
      },
      {
        date: "2023.10.20",
        content: "10月26日(木)に野村證券が共催する 「VENTURE PITCH ONLINE」に弊社が登壇致します。",
        href: "https://vp55.peatix.com/view"
      },
      {
        date: "2023.10.18",
        content: "神戸市が主催する神戸市内企業と市内・全国のパートナー企業とのオープンイノベーションプログラム『KOBE OPEN INNOVATION「Flag」2023（以下、本プログラム）』において、弊社がプロジェクト/共創パートナーとして採択されました。",
        href: "https://prtimes.jp/main/html/rd/p/000000483.000037194.html"
      },
      {
        date: "2023.10.18",
        content: "KDDIが進めている「事業共創プラットフォーム」KDDI ∞ Labo（ムゲンラボ）にて弊社の取組が取り上げられました。",
        href: "https://mugenlabo-magazine.kddi.com/list/shochiku_olive/"
      },
      {
        date: "2023.09.29",
        content: "日本最大級のオープンイノベーション見本市「Japan Open Innovation Fes 2023（JOIF2023）」に弊社がGold startupとして出展、登壇致します。",
        href: "https://eiicon.net/about/joif2023/"
      },
      {
        date: "2023.09.20",
        content: "多様なスタートアップとの接点を持つCrewwと、エンターテイメント・to C 領域に強みを持つベンチャーキャピタルDawn Capitalによるコラボイベントにて弊社が登壇致します。",
        href: "https://creww-dawn20230927.peatix.com/"
      },
      {
        date: "2023.09.19",
        content: "三菱UFJ銀行主催に因るオープンイノベーションに関心のある中部地区事業会社の経営者を集めたイベントにて弊社が登壇致しました。",
        href: ""
      },
      {
        date: "2023.09.02",
        content: "弊社の取組が2023年9月2日放送のZIP FM Think about the future Startup [N]にて紹介されました。",
        href: "https://youtu.be/JTnqSE0pb3g"
      },
      {
        date: "2023.08.11",
        content: "弊社の取組が2023年8月11日放送のワールドビジネスサテライトにて紹介されました。",
        href: "https://www.tv-tokyo.co.jp/wbs/"
      },
      {
        date: "2023.08.04",
        content: "Oliveは松竹と映画鑑賞における感情データの取得を開始。",
        href: "https://prtimes.jp/main/html/rd/p/000000024.000104108.html"
      },
      {
        date: "2023.08.03",
        content: "日本最大級のオープンイノベーション見本市「Japan Open Innovation Fes 2023（JOIF2023）」に STARTUP GOLD として参加・出展致します。",
        href: "https://prtimes.jp/main/html/rd/p/000000460.000037194.html"
      },
      {
        date: "2023.07.24",
        content: "弊社はこのほど2023年6月30日に設立された「健康経営アライアンス」に参画しましたのでお知らせいたします。",
        href: "https://kenkokeiei-alliance.com/"
      },
      {
        date: "2023.07.08",
        content: "中日新聞に弊社取組が掲載されました。",
        href: "https://www.chunichi.co.jp/article/724719"
      },
      {
        date: "2023.07.07",
        content: "株式会社帝国データバンクが発行している『帝国ニュース 中部版 No.8869』の「注目！スタートアップ中部」に弊社が掲載されました。",
        href: "/TDB_startup_20230707.pdf"
      },
      {
        date: "2023.06.14",
        content: "2023年7月6日に行われる三菱総合研究所主催の「ICF Meetup 2023」において、「これからのウェルビーイング社会」や「ウェルネス戦略におけるスタートアップの役割と期待」の観点にて登壇企業の1社として弊社も登壇させて頂きます。",
        href: "https://mri.smartseminar.jp/public/seminar/view/5723"
      },
      {
        date: "2023.06.13",
        content: "名古屋大学、東芝グループをはじめとしたコンソ―シアムと進めている「遠隔診療BOX」の取組が紹介されました（弊社は感情推定部分を担当）。",
        href: "https://dempa-digital.com/article/335730"
      },
      {
        date: "2023.06.05",
        content: "弊社の教育向け取組がNHKにて紹介されました。",
        href: "https://www3.nhk.or.jp/news/html/20230605/k10014089601000.html"
      },
      {
        date: "2023.06.04",
        content: "弊社の観光DX向け取組が西日本新聞にて紹介されました。",
        href: "https://www.nishinippon.co.jp/item/n/1094682/"
      },
      {
        date: "2023.06.02",
        content: "この度、弊社は株式会社三菱総合研究所が運営する未来共創イニシアティブのベンチャー会員にお招き頂きました。",
        href: "https://icf.mri.co.jp/member/"
      },
      {
        date: "2023.03.16",
        content: "Olive株式会社は松竹ベンチャーズ株式会社、松竹芸能株式会社と共に、新たなライブエンタテインメントの創出に向けた実証実験を行いました。",
        href: "https://prtimes.jp/main/html/rd/p/000000017.000104108.html"
      },
      {
        date: "2023.03.13",
        content: "Olive株式会社の提供する生体情報による「感情の可視化」技術が文部科学省の令和4年度「次世代の学校・教育現場を見据えた先端技術・教育データの利活用推進事業」に採択されました。",
        href: "/press_edu_202303.pdf"
      },
      {
        date: "2023.03.01",
        content: "Olive株式会社及び株式会社IDMは、大分県別府市、大分みらい信用金庫及び信金中央金庫に因る後援・協力の元、観光DX時代の観光地ソリューションとして2023年3月より別府市において共創プロジェクトの形で開始いたします。",
        href: "https://prtimes.jp/main/html/rd/p/000000004.000115414.html"
      },
      {
        date: "2023.01.27",
        content: "SMBCグループが主催するアクセラレーションプログラム未来X2023にて弊社が『証券DX賞』を受賞致しました。これは「デジタルの良さを活かしたCXの向上、データアナリティクスによる営業員やお客さまの課題解決、動きの速いマーケットや多様化するお客さまニーズに対応する」というニーズに対し弊社が選ばれたものとなります。",
        href: "https://mirai-cross.ventures/program/program-3666/"
      },
      {
        date: "2022.11.10",
        content: "松竹グループのコーポレート・ベンチャー・キャピタル（CVC）「松竹ベンチャーズ株式会社」が主催するアクセラレータープログラム Shochiku Accelerator2022「Entertainment Festival」にて、弊社が採択されました。",
        href: "https://prtimes.jp/main/html/rd/p/000000008.000104108.html"
      },
      {
        date: "2022.09.02",
        content: "しんきんイノベーションプロジェクト in 別府 DEMO DAYにて、観光タクシーによる観光活性化PoCの発表を実施致しました。",
        href: "http://www.oitamirai.co.jp/pdf/20220817_001.pdf"
      },
      {
        date: "2022.04.14",
        content: "トータルセキュリティ製品を開発・製造している(株)熊平製作所と「次世代セキュリティに向けた新たな価値創造プロジェクト」に向けた取組を開始することとなりました。",
        href: "https://www.hirogin.co.jp/ir/news/paper/news220413.pdf"
      },
      {
        date: "2022.03.30",
        content: "ニューノーマル時代の働き方を実現するオフィス「つなぐオフィス」を展開するコニカミノルタジャパン株式会社と同社のAIカメラ『MOBOTIX』 を活用した感情の見える化プロジェクトを開始致しました",
        href: "https://www.konicaminolta.jp/business/information/release/220329.html"
      },
      {
        date: "2022.03.24",
        content: "長野県として初めて開催したオープンイノベーションプログラム「NAGANO-OIC 2021」に参加、長野朝日放送株式会社と視聴者の感情の見える化の実証実験を実施致しました。",
        href: "https://www.kanto.meti.go.jp/seisaku/open_innovation/nagano_oic2021.html"
      },
      {
        date: "2022.03.20",
        content: "3月16日（水）に行われた十六銀行が主催するベンチャー助成事業「第21回『NOBUNAGA21』地域経済活性化セミナー 新時代へつなぐ～NOBUNAGAの挑戦～」にて、弊社が「ヒトの感情の見える化とその活用による察する世界の実現」として応募し、最優秀賞受賞と共に助成金100万円をいただきました。",
        href: "https://www.juroku.co.jp/sp/corp/support/nobunaga21.html"
      },
      {
        date: "2021.12.09",
        content: "2022年01月25日(火) 技術情報協会主催セミナー 「感性の計測技術と製品開発への応用」にChief Scientist 橋本 一生 が登壇します！",
        href: "https://www.atengineer.com/pr/gijutu/20211201001.html"
      },
      {
        date: "2021.11.18",
        content: "Panasonic Accelerator by Electric Works Company に採択されました！！",
        href: "https://news.panasonic.com/jp/topics/204472.html"
      },
      {
        date: "2021.07.12",
        content: "富士通アクセラレーター for Work Life Shift に採択されました！！",
        href: "https://prtimes.jp/main/html/rd/p/000000018.000037120.html"
      },
      {
        date: "2021.06.16",
        content: "Plug and Play Japan株式会社が運営する次期プログラム Winter/Spring 2022 Batch 開催に伴うスタートアップ向けにオンラインミートアップに登壇予定です。",
        href: "https://japan.plugandplaytechcenter.com/events/2021_meetup_jp/"
      },
      {
        date: "2021.04.12",
        content: "Plug and Play Japan株式会社が運営する「Winter/Spring 2021 Summit」にて、Automotive部門の「EXPO Startup Award」を受賞しました。",
        href: "https://japan.plugandplaytechcenter.com/blog/ws2021summitwinners/"
      },
      {
        date: "2021.02.08",
        content: "プロジェクト Oliveが「SmartCityX」に採択されました。",
        href: "https://scrum.vc/ja/2021/02/01/press-release-2021-02-02/"
      }
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow flex items-center justify-center">
        <div className="w-full max-w-4xl mx-auto px-5">
          <section className="text-black font-gothic">
            <h2 className="text-3xl text-center mb-6 font-gothic">{t("news")}</h2>
            <div className="bg-white border border-gray-200 p-6 rounded-xl border-2 border-black border-solid !border-opacity-100 font-gothic mb-2">
              {pressReleases.map((item, index) => (
                <div key={index} className="border-b border-black pb-4 mb-4 bg-gray-50 rounded-lg p-4">
                  <a 
                    href={item.href} 
                    className="text-base leading-relaxed text-black no-underline hover:text-[#888888] transition-colors duration-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="block font-bold mb-2">{item.date}</span>
                    <span className="block">{item.content}</span>
                  </a>
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Pressrelease;